<template>
  <div class="container">
    <div class="wrapper">
      <img
        class="img-404"
        :src="NotFound"
        alt="Not Found"
      >

      <div class="text-content">
        <h3 class="text-center">
          Page was not found!
        </h3>
        <div class="w-100 d-flex justify-center mt-5">
          <v-btn
            :to="{name : 'Home'}"
            color="primary"
            large
          >
            <v-icon left>
              mdi-arrow-left
            </v-icon>
            Back to home
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotFound from '@/assets/images/404/404.svg';
export default {
  name: 'NotFound',
  setup () {

    return {
      NotFound
    };
  },
};
</script>

<style scoped>
.wrapper {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10vh 0;
}
.text-content {
  margin: 20px 0;
}
.text-content h3 {
  font-size: clamp(1.75rem, 0.741vw + 1.611rem, 2.5rem);
  line-height: clamp(2.625rem, 0.617vw + 2.509rem, 3.25rem);
  color: #464444;
  margin: 20px 0;
}
.text-content a {
  /*font-size: 20px;*/
}
.img-404 {
  width: 30%;
}

@media screen and (max-width: 600px) {
  .img-404 {
    width: 90%;
  }

}
</style>
